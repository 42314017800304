<template>
  <div v-loading.fullscreen.lock="loading" id="settings_carpooling_ticket">
    <div class="not-on-print">
      <inner-page-title title="Carpooling ticket"></inner-page-title>

      <div class="container settings-options">
        <div class="image-container">
          <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'users']"></font-awesome-icon>
        </div>
        <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">How it works</span>
        <p>Everyday you carpool, you will be elegible to use the carpooling car parking spaces at your organization. If you are not a driver, you do not need to worry about this. In order for us to verify that you have carpooled, you need to:</p>

        <br>
        <p><b>1. Have your details up to date:&nbsp;&nbsp;</b>Make sure your car registration plate is correct on your profile. You can do this in <i>Settings > Carpooling details</i>.</p>

        <br>
        <p><b>2. Download your permit:&nbsp;&nbsp;</b>Below, you will find a button to download your carpooling permit, you will need to print this permit. You can do this through your phone, although we recommend using a computer to keep it simple.</p>

        <br>
        <p><b>3. Place on your car:&nbsp;&nbsp;</b>After printing the permit, fold it in half and place it on the front window of your car when you are driving. Make sure it's visible.</p>

        <br>
        <p><b>4. Follow the rules:&nbsp;&nbsp;</b>Anyone can scan the QR code on your permit and check if you had carpooled that day. Make sure you only park on the carpooling-only spaces only the days you carpool. There is people actively checking the cars and you may get clamped if you don't follow the rules.</p>

        <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
          <el-button type="primary" class="secondary-color" @click="onDownloadPDF">Download PDF</el-button>
        </div>
      </div>
    </div>

    <div id="carpooling_permit">
      
      <div class="permit-half">
        <div class="qr-and-info">
          <div class="qr-image">
            <img :src="`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${domain}/crp?crp=${car_reg_plate}`" alt="">
          </div>
          <div class="other-info">
            <div style="width: 100%;">
              <img style="width: 43%; margin: auto" :src="basePath + 'img/logo-black.png'">
            </div>
            <h3 style="font-size: 22px; font-weight: 500; margin-bottom: 7px;">Carpooling parking permit</h3>
            <span style="display: block; text-align: center; font-size: 30px; padding: 10px; border: 1px solid rgba(0,0,0,0.1); border-radius: 5px; box-shadow: 4px 4px 4px 2px rgba(0,0,0,0.1)">{{ formatted_car_reg_plate }}</span>

          </div>
        </div>

        <div class="notice-info">
          <p>This permit grants drivers the right to use one of the parking spaces reserved for carpooling. 
          <b>This permit is only valid on the days you have carpooled with someone on the way into college/work. Please ensure you have set your carpooling trip correctly on the Sharo app.</b>
          Please place this permit on the dashboard of your car against the windshield. It needs to be easily seen by anyone.
          Anyone can use the Sharo app to check if you have carpooled on any given day by scanning the QR code above.
          Checks are done regularly and penalties will apply for unauthorized use of carpooling-only parking spaces.</p>
        </div> 
        
      </div>
      <div class="permit-half permit-half-empty">
        <span>Fold here</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    domain: process.env.VUE_APP_DOMAIN,
    basePath: process.env.BASE_URL
  }),

  methods: {
    onDownloadPDF() {
      if (!this.user.carpooling_details.driver) {
        return this.$message({ type: 'error', showClose: true, message: 'You need to be set as a driver and your car registration complete' });
      }

      if (!this.user.car_reg_plate) {
        return this.$message({ type: 'error', showClose: true, message: 'You need to have your car registration plate in your profile' });
      }
      
      if (!window.print || ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
        return this.$message({ type: 'error', showClose: true, message: "Error: your mobile doesn't support this. Try from a computer instead" });
      }

      return window.print();
    }
  },

  computed: {
    user() {
      return this.$store.getters.user.data;
    },

    car_reg_plate() {
      return this.$store.getters.user.data.car_reg_plate && this.$store.getters.user.data.car_reg_plate.trim().replace(/ /g, "").toUpperCase() || '';
    },

    formatted_car_reg_plate() {
      return this.format_car_reg_plate(this.$store.getters.user.data.car_reg_plate || '');
    }
  }
}
</script>

<style lang="scss" scoped>
  .container.settings-options {
    max-width: 600px;

    .form-item {
      margin-bottom: 10px;
    }
  }

  .image-container {
    border-radius: 50%;
    background-color: #d1f1ff;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: auto;
    margin-bottom: 15px;
  }

  #carpooling_permit {
    display: none;
  }

  @media print {
    @page {
      size: A4 !important;
      margin: 0;
    }

    html, body {
      width: 210mm !important;
      height: 297mm !important;
    }

    .not-on-print {
      display: none;
    }

    #carpooling_permit {
      border: 15px solid rgba(33, 168, 73, 0.8);
      display: block;
      padding: 30px;
      display: flex;
      flex-direction: column;
      // height: 100vh !important;

      width: 100%;
      height:100%;
      position:absolute;
      top:0px;
      bottom:0px;
      margin: auto;
      margin-top: 0px !important;

      .permit-half.permit-half-empty {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 2px dashed rgb(0,0,0);
      }


      .permit-half:not(.permit-half-empty) {
        flex: 1;
        padding: 0px 0 20px 0;
        
        .qr-and-info {
          display: flex;
          width: 100%;
          
          .qr-image {
            padding-right: 10px;
            flex: 1;
            img {
              width: 100%;
            }
          }

          .other-info {
            padding-left: 10px;
            flex: 1;
          }
        }

        .notice-info {
          margin-top: 5px;
        }
      }

    }
  }

</style>