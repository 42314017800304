var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_carpooling_ticket" }
    },
    [
      _c(
        "div",
        { staticClass: "not-on-print" },
        [
          _c("inner-page-title", { attrs: { title: "Carpooling ticket" } }),
          _c("div", { staticClass: "container settings-options" }, [
            _c(
              "div",
              { staticClass: "image-container" },
              [
                _c("font-awesome-icon", {
                  staticStyle: { color: "rgba(0,0,0,0.6)" },
                  attrs: { icon: ["fas", "users"] }
                })
              ],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  display: "block",
                  "font-size": "20px",
                  "margin-bottom": "5px",
                  "text-align": "center"
                }
              },
              [_vm._v("How it works")]
            ),
            _c("p", [
              _vm._v(
                "Everyday you carpool, you will be elegible to use the carpooling car parking spaces at your organization. If you are not a driver, you do not need to worry about this. In order for us to verify that you have carpooled, you need to:"
              )
            ]),
            _c("br"),
            _vm._m(0),
            _c("br"),
            _vm._m(1),
            _c("br"),
            _vm._m(2),
            _c("br"),
            _vm._m(3),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "20px",
                  "margin-bottom": "20px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "secondary-color",
                    attrs: { type: "primary" },
                    on: { click: _vm.onDownloadPDF }
                  },
                  [_vm._v("Download PDF")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { attrs: { id: "carpooling_permit" } }, [
        _c("div", { staticClass: "permit-half" }, [
          _c("div", { staticClass: "qr-and-info" }, [
            _c("div", { staticClass: "qr-image" }, [
              _c("img", {
                attrs: {
                  src:
                    "https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=" +
                    _vm.domain +
                    "/crp?crp=" +
                    _vm.car_reg_plate,
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "other-info" }, [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("img", {
                  staticStyle: { width: "43%", margin: "auto" },
                  attrs: { src: _vm.basePath + "img/logo-black.png" }
                })
              ]),
              _c(
                "h3",
                {
                  staticStyle: {
                    "font-size": "22px",
                    "font-weight": "500",
                    "margin-bottom": "7px"
                  }
                },
                [_vm._v("Carpooling parking permit")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "text-align": "center",
                    "font-size": "30px",
                    padding: "10px",
                    border: "1px solid rgba(0,0,0,0.1)",
                    "border-radius": "5px",
                    "box-shadow": "4px 4px 4px 2px rgba(0,0,0,0.1)"
                  }
                },
                [_vm._v(_vm._s(_vm.formatted_car_reg_plate))]
              )
            ])
          ]),
          _vm._m(4)
        ]),
        _vm._m(5)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("1. Have your details up to date:  ")]),
      _vm._v(
        "Make sure your car registration plate is correct on your profile. You can do this in "
      ),
      _c("i", [_vm._v("Settings > Carpooling details")]),
      _vm._v(".")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("2. Download your permit:  ")]),
      _vm._v(
        "Below, you will find a button to download your carpooling permit, you will need to print this permit. You can do this through your phone, although we recommend using a computer to keep it simple."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("3. Place on your car:  ")]),
      _vm._v(
        "After printing the permit, fold it in half and place it on the front window of your car when you are driving. Make sure it's visible."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("4. Follow the rules:  ")]),
      _vm._v(
        "Anyone can scan the QR code on your permit and check if you had carpooled that day. Make sure you only park on the carpooling-only spaces only the days you carpool. There is people actively checking the cars and you may get clamped if you don't follow the rules."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notice-info" }, [
      _c("p", [
        _vm._v(
          "This permit grants drivers the right to use one of the parking spaces reserved for carpooling. \n        "
        ),
        _c("b", [
          _vm._v(
            "This permit is only valid on the days you have carpooled with someone on the way into college/work. Please ensure you have set your carpooling trip correctly on the Sharo app."
          )
        ]),
        _vm._v(
          "\n        Please place this permit on the dashboard of your car against the windshield. It needs to be easily seen by anyone.\n        Anyone can use the Sharo app to check if you have carpooled on any given day by scanning the QR code above.\n        Checks are done regularly and penalties will apply for unauthorized use of carpooling-only parking spaces."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "permit-half permit-half-empty" }, [
      _c("span", [_vm._v("Fold here")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }